.img1[data-v-07ff1bf5] {
  height: 109px;
  margin-left: 29px;
  margin-top: 19px;
  border-radius: 50%;
  width: 109px;
}
.title[data-v-07ff1bf5] {
  font-weight: 300;
  font-size: 21px;
  line-height: 52px;
  color: #000000;
  margin: 25px 0px 10px 23px;
}
.input[data-v-07ff1bf5] {
  margin: 5px 0px 180px 25px;
}
.container .card[data-v-07ff1bf5] {
  position: relative;
  /* height: 389px; */
  background: #fff;
  margin-top: 84px;
  margin-bottom: 84px;
}
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
*[data-v-07ff1bf5] {
  /* margin: 0; */
  /* padding: 0; */
  outline: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.wrapper .input-data input[data-v-07ff1bf5] {
  width: 100%;
  border: none;
  font-size: 27px;
  border-bottom: 2px solid silver;
  padding: 20px;
}
.input-data input:focus ~ label[data-v-07ff1bf5],
.input-data input:valid ~ label[data-v-07ff1bf5] {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  font-size: 15px;
  color: #4158d0;
}
.wrapper .input-data label[data-v-07ff1bf5] {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all 0.3s ease;
}
.input-data .underline[data-v-07ff1bf5]:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #4158d0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.input-data input:focus ~ .underline[data-v-07ff1bf5]:before,
.input-data input:valid ~ .underline[data-v-07ff1bf5]:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
hr[data-v-07ff1bf5] {
  border-bottom: 0.2px solid #070707;
  margin: 10px 0;
  width: 72%;
}
#updateFormBtn[data-v-07ff1bf5] {
  background-color: coral;
  color: #000;
  border: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  float: right;
  cursor: pointer;
  width: 100%;
}
