.img1[data-v-b1b05031] {
  height: 109px;
  margin-left: 29px;
  margin-top: 19px;
}
.title[data-v-b1b05031] {
  font-weight: 300;
  font-size: 21px;
  /* line-height: 52px; */
  color: #000000;
}
.input-data[data-v-b1b05031]{
 
  position: relative;
}
.input-data input[data-v-b1b05031]{
  /* width:100px; */
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.vehicleInfo[data-v-b1b05031]{
    color:coral;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.02em;
    line-height: 21px;
    margin-bottom: 23px;
    margin-top: 30px;
}
.input[data-v-b1b05031] {
  margin: 5px 0px 180px 25px;
}
select[data-v-b1b05031] {
  /* margin: 5px 0px 0px 25px; */
  margin-left: 25px;
  height: 77px;
  width: 100%;
  border: none;
  font-size: 20px;
  border-bottom: 2px solid silver;
  width: 90%;
  height: 100%;
}
.container .card[data-v-b1b05031] {
  position: relative;
  /* height: 389px; */
  background: none;
  margin-top: 84px;
  margin-bottom: 84px;
}
.main[data-v-b1b05031]{
    text-align: center;
    color: #ffe6d9;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    line-height: 28px;
    margin-bottom: 0;
    margin-top: 25px;
}
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
*[data-v-b1b05031] {
  /* margin: 0; */
  /* padding: 0; */
  outline: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.wrapper .input-data input[data-v-b1b05031] {
  width: 100%;
  border: none;
  font-size: 15px;
  /* border-bottom: 2px solid silver; */
  padding: 20px;
}
.input-data input:focus ~ label[data-v-b1b05031],
.input-data input:valid ~ label[data-v-b1b05031] {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  font-size: 15px;
  color: #4158d0;
}
.wrapper .input-data label[data-v-b1b05031] {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all 0.3s ease;
}
.input-data .underline[data-v-b1b05031]:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #4158d0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.input-data input:focus ~ .underline[data-v-b1b05031]:before,
.input-data input:valid ~ .underline[data-v-b1b05031]:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
hr[data-v-b1b05031] {
  border-bottom: 0.2px solid #070707;
  margin: 10px 0;
  width: 72%;
}
#updateFormBtn[data-v-b1b05031] {
    align-items: center;
    background-color: coral;
    border: none;
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    display: flex;
    float: right;
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    height: 52px;
    justify-content: center;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}
