.card12[data-v-5bd8ed0c] {
  background: #d9dbed;
  border: 4px dashed #c0c0c0;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  display: flex;
  padding: 23px;
}
.btn12[data-v-5bd8ed0c] {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  /* box-shadow: 0px 1px 1px rgb(0 0 0 / 6%); */
  border-radius: 8px;
  width: 165px;
  height: 47px;
}
.btnn1[data-v-5bd8ed0c] {
  background: #059454;
  border-radius: 10px;
  height: 50px;
  width: 90%;
  border: none;
  color: white;
}
.drag-area[data-v-5bd8ed0c] {
  border: 2px dashed #fff;
  height: 500px;
  width: 700px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.drag-area.active[data-v-5bd8ed0c] {
  border: 2px solid #fff;
}
.drag-area .icon[data-v-5bd8ed0c] {
  font-size: 100px;
  color: #fff;
}
.drag-area header[data-v-5bd8ed0c] {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
}
.drag-area span[data-v-5bd8ed0c] {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin: 10px 0 15px 0;
}
.drag-area button[data-v-5bd8ed0c] {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}
.drag-area img[data-v-5bd8ed0c] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
#img1[data-v-5bd8ed0c] {
  width: auto;
}
#div1[data-v-5bd8ed0c] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
#div2[data-v-5bd8ed0c] {
  margin: auto;
}
#div3[data-v-5bd8ed0c] {
  text-align: center;
}
.div4[data-v-5bd8ed0c] {
  color: darkgray;
  text-align: left;
}
#div5[data-v-5bd8ed0c] {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.4px;
}
#img2[data-v-5bd8ed0c] {
  width: auto;
}
select[data-v-5bd8ed0c] {
  background: #d9dbed;
  border-radius: 10px;
  height: 50px;
  border: none;
  outline: none;
}
