.p-30[data-v-3b5a8a75] {
  padding: 30px;
}
.main-datatable[data-v-3b5a8a75] {
  padding: 0px;
  border: 1px solid #f3f2f2;
  border-bottom: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}
.d-flex[data-v-3b5a8a75] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_body[data-v-3b5a8a75] {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.main-datatable .row[data-v-3b5a8a75] {
  margin: 0;
}
.searchInput[data-v-3b5a8a75] {
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  margin: 20px 0px;
  padding: 0px 4px;
}
.searchInput input[data-v-3b5a8a75] {
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  margin-left: 8px;
  height: 34px;
  width: 100%;
  padding: 0px 25px 0px 10px;
  transition: all 0.6s ease;
}
.searchInput label[data-v-3b5a8a75] {
  color: #767676;
  font-weight: normal;
}
.searchInput input[data-v-3b5a8a75]:-ms-input-placeholder {
  width: 13%;
}
.searchInput input[data-v-3b5a8a75]:placeholder-shown {
  width: 13%;
}
.searchInput:hover input[data-v-3b5a8a75]:-ms-input-placeholder {
  width: 100%;
  cursor: pointer;
}
.searchInput:hover input[data-v-3b5a8a75]:placeholder-shown {
  width: 100%;
  cursor: pointer;
}
.searchInput[data-v-3b5a8a75]:after {
  font-family: "FontAwesome";
  color: #d4d4d4;
  position: relative;
  content: "\f002";
  right: 25px;
}
.dim_button[data-v-3b5a8a75] {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  background: rgb(57, 85, 136);
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 20px;
}
.createSegment a[data-v-3b5a8a75] {
  margin-bottom: 0px;
  border-radius: 50px;
  background: #ffffff;
  border: 1px solid #007bff;
  color: #007bff;
  transition: all 0.4s ease;
}
.createSegment a[data-v-3b5a8a75]:hover,
.createSegment a[data-v-3b5a8a75]:focus {
  transition: all 0.4s ease;
  background: #007bff;
  color: #fff;
}
.add_flex[data-v-3b5a8a75] {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}
.main-datatable .dataTable.no-footer[data-v-3b5a8a75] {
  border-bottom: 1px solid #eee;
}
.main-datatable .cust-datatable thead[data-v-3b5a8a75] {
  background-color: #f9f9f9;
}
.main-datatable .cust-datatable > thead > tr > th[data-v-3b5a8a75] {
  border-bottom-width: 0;
  color: #443f3f;
  font-weight: 600;
  padding: 16px 15px;
  vertical-align: middle;
  padding-left: 18px;
  text-align: center;
}
.main-datatable .cust-datatable > tbody td[data-v-3b5a8a75] {
  padding: 10px 15px 10px 18px;
  color: #333232;
  font-size: 13px;
  font-weight: 500;
  word-break: break-word;
  border-color: #eee;
  text-align: center;
  vertical-align: middle;
}
.main-datatable .cust-datatable > tbody tr[data-v-3b5a8a75] {
  border-top: none;
}
.main-datatable .table > tbody > tr[data-v-3b5a8a75]:nth-child(even) {
  background: #f9f9f9;
}
.btn-group.open .dropdown-toggle[data-v-3b5a8a75] {
  box-shadow: none;
}
.main-datatable .dropdown_icon[data-v-3b5a8a75] {
  display: inline-block;
  color: #8a8a8a;
  font-size: 12px;
  border: 1px solid #d4d4d4;
  padding: 10px 11px;
  border-radius: 50%;
  cursor: pointer;
}
.btn-group i[data-v-3b5a8a75] {
  color: #8e8e8e;
  margin: 2px;
}
.main-datatable .actionCust a[data-v-3b5a8a75] {
  display: inline-block;
  color: #8a8a8a;
  font-size: 12px;
  border: 1px solid #d4d4d4;
  padding: 10px 11px;
  margin: -9px 3px;
  border-radius: 50%;
  cursor: pointer;
}
.main-datatable .actionCust a i[data-v-3b5a8a75] {
  color: #8e8e8e;
  margin: 2px;
}
.main-datatable .dropdown-menu[data-v-3b5a8a75] {
  padding: 0;
  border-radius: 4px;
  box-shadow: 10px 10px 20px #c8c8c8;
  margin-top: 10px;
  left: -65px;
  top: 32px;
}
.main-datatable .dropdown-menu > li > a[data-v-3b5a8a75] {
  display: block;
  padding: 12px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap;
  border-bottom: 1px solid #d4d4d4;
}
.main-datatable .dropdown-menu > li > a[data-v-3b5a8a75]:hover,
.main-datatable .dropdown-menu > li > a[data-v-3b5a8a75]:focus {
  color: #fff;
  background: #007bff;
}
.main-datatable .dropdown-menu > li > a:hover i[data-v-3b5a8a75] {
  color: #fff;
}
.main-datatable .dropdown-menu[data-v-3b5a8a75]:before {
  position: absolute;
  top: -7px;
  left: 78px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #d4d4d4;
  border-left: 7px solid transparent;
  border-bottom-color: #d4d4d4;
  content: "";
}
.main-datatable .dropdown-menu[data-v-3b5a8a75]:after {
  position: absolute;
  top: -6px;
  left: 78px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}
.dropdown-menu i[data-v-3b5a8a75] {
  margin-right: 8px;
}
.main-datatable .dataTables_wrapper .dataTables_paginate .paginate_button[data-v-3b5a8a75] {
  color: #999999 !important;
  background-color: #f6f6f6 !important;
  border-color: #d4d4d4 !important;
  border-radius: 40px;
  margin: 5px 3px;
}
.main-datatable
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button[data-v-3b5a8a75]:hover {
  color: #fff !important;
  border: 1px solid #3d96f5 !important;
  background: #4da3ff !important;
  box-shadow: none;
}
.main-datatable
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current[data-v-3b5a8a75],
.main-datatable
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current[data-v-3b5a8a75]:hover {
  color: #fff !important;
  border-color: transparent !important;
  background: #007bff !important;
}
.main-datatable .dataTables_paginate[data-v-3b5a8a75] {
  padding-top: 0 !important;
  margin: 15px 10px;
  float: right !important;
}
.mode[data-v-3b5a8a75] {
  padding: 4px 10px;
  line-height: 13px;
  color: #fff;
  font-weight: 400;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  font-size: 11px;
  letter-spacing: 0.4px;
}
.mode_on[data-v-3b5a8a75] {
  background-color: #09922d;
}
.mode_off[data-v-3b5a8a75] {
  background-color: #8b9096;
}
.mode_process[data-v-3b5a8a75] {
  background-color: #ff8000;
}
.mode_done[data-v-3b5a8a75] {
  background-color: #03a9f3;
}
@media only screen and (max-width: 1200px) {
.overflow-x[data-v-3b5a8a75] {
    overflow-x: scroll;
}
.overflow-x[data-v-3b5a8a75]::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}
.overflow-x[data-v-3b5a8a75]::-webkit-scrollbar-thumb {
    background-color: #888;
}
.overflow-x[data-v-3b5a8a75]::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
}
#div1[data-v-3b5a8a75] {
  text-align: left;
  display: flex;
  justify-content: space-between;
}
#div2[data-v-3b5a8a75] {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.4px;
}
#div3[data-v-3b5a8a75] {
  text-align: left;
}
#div4[data-v-3b5a8a75] {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.4px;
}
#img11[data-v-3b5a8a75] {
  width: auto;
}
label[data-v-3b5a8a75] {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.4px;
  text-align: left;
}
.form-group[data-v-3b5a8a75] {
  text-align: left;
}
.custom-input[data-v-3b5a8a75] {
  background-color: #f9f9f9;
  padding: 10px;
  display: flex;
  border: none;
  width: 100%;
  border-radius: 5px;
}
.custom-input input[data-v-3b5a8a75] {
  border: none;
  background-color: #f9f9f9;
  width: 100%;
  padding: 10px;
}
.custom-input input[data-v-3b5a8a75]:focus {
  outline: none;
}
.form-check-input[data-v-3b5a8a75] {
  width: 20px;
  height: 20px;
  right: 10px;
  border-radius: 50%;
}
.form-check-label[data-v-3b5a8a75] {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.4px;
}
.form-check[data-v-3b5a8a75] {
  margin-left: 0px;
  padding: 10px;
  background-color: #f9f9f9;
  margin: 10px 0px;
  width: 100%;
}
.image-uploadBlock[data-v-3b5a8a75] {
  justify-content: space-between;
  align-items: center;

  justify-content: center;
}
.image-upload[data-v-3b5a8a75] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 26% 19% 10% 20%; */
  border: 1px solid #f9f9f9;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  flex-direction: column;
  gap: 5%;
  padding-bottom: 18%;
  padding-top: 14%;
}
.hidden[data-v-3b5a8a75] {
  display: none;
}
.imageUploadElements[data-v-3b5a8a75] {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 5%;
}
.imageUploadElements img[data-v-3b5a8a75] {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.imageUploadElements i[data-v-3b5a8a75] {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f9f9f9;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}
.imageUploadElements i[data-v-3b5a8a75]:hover {
  background-color: #f9f9f9;
}

/* make the checkbox in radius bit circle */
.form-check-input[data-v-3b5a8a75] {
  width: 20px;
  height: 20px;
  right: 10px;
  border-radius: 50%;
}
.actionBtns[data-v-3b5a8a75] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  left: 20rem;
  position: relative;
}
.actionBtns button[data-v-3b5a8a75] {
  padding: 10px 20px;
  border-radius: none;
  /* background-color: #; */
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.previewBlock[data-v-3b5a8a75] {
  display: flex;
  /* justify-content: center;
    align-items: center; */
  /* gap: 10px; */
  margin-left: 0%;
  padding-left: 0%;
}
.imagePreivewBlock[data-v-3b5a8a75] {
  background-color: #d9d9d9;
  width: 780px;
  height: 1150px;
  border-radius: 5px;
  display: flex;
  /* justify-content: center; */
  padding: 2rem;
  /* gap:10rem; */
  /* border: #333232; */
  /* border: 2px solid #333232; */
  /* add shadow */
}
.carousel[data-v-3b5a8a75] {
  /* fit width to the parent div */
  width: 100%;
  /* height: 352px; */
  /* border-radius: 5px; */
}
/* make the carousel images to have rounded border */
.carousel img[data-v-3b5a8a75] {
  /* border-radius: 20px; */
  width: 100%;
  height: 452px;
}
.carousel-indicators[data-v-3b5a8a75] {
  bottom: 1rem;
}
.carousel-indicators li[data-v-3b5a8a75] {
  background-color: #feae4f;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  /* margin-top: 15%; */
}
.carousel-indicators .active[data-v-3b5a8a75] {
  background-color: #fff;
  width: 8px;
  height: 8px;
}
.statsCardContainer[data-v-3b5a8a75] {
  position: relative;
  top: -80px;
  left: 50px;
}
.statsCard[data-v-3b5a8a75] {
  /* gap: 20px; */
  /* margin-top: 20px; */
  /* flex-direction: column; */
  border-bottom: #767676 1px solid;
  padding: 10px;
}
.statsCard h5[data-v-3b5a8a75] {
  font-size: 16px;
  color: #9fa2b4;
}
.statsCard h3[data-v-3b5a8a75] {
  /* font-size: 30px; */

  color: #333232;

  font-size: 30px;
  font-weight: 600;
  color: #333232;
}
.mobileCardView[data-v-3b5a8a75] {
  position: relative;
  width: 230px;
  height: 400px;
  border-radius: 5px;
  background-color: #fff;
  /* box-shadow:5px solid black; */
  /* drop shadow over the card */
  box-shadow: 10px rgba(0, 0, 0, 0.05);
  margin: 0px 10px;
  text-align: center;
}
.CardViewImgBlock[data-v-3b5a8a75] {
  height: 48%;
  width: 100%;
}
.CardViewImgBlock img[data-v-3b5a8a75] {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
}
.CardViewText[data-v-3b5a8a75] {
  padding: 10px;
}
.CardViewText h5[data-v-3b5a8a75] {
  font-size: 20px;
  /* color: #9fa2b4; */
  /* font-weight: 600; */
  /* semi-bold */
  color: #333232;
  font-weight: 600;
}
.CardViewText h6[data-v-3b5a8a75] {
  font-size: 20px;
  color: #4cad73;
}
.CardViewText p[data-v-3b5a8a75] {
  font-size: 16px;
  color: #333232;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.likeBlock[data-v-3b5a8a75] {
  align-items: center;
  width: 30px;
  margin-left: auto;
  border-top: 1px solid #f3f2f2;
}
.mobileCardViewlg[data-v-3b5a8a75] {
  position: relative;
  width: 430px;
  height: 950px;
  border-radius: 5px;
  background-color: #f5f3f3;
  /* box-shadow:5px solid black; */
  /* drop shadow over the card */
  box-shadow: 10px rgba(0, 0, 0, 0.05);
  margin: 0px 10px;
  text-align: center;
}
.mobileCardViewlgContent[data-v-3b5a8a75] {
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  /* gap: 20px; */
}

/* MobilecontentPart from the bottom of the carosel */

/* Inside mobilenewcarosel.scoped.css */
.carousel-item img[data-v-3b5a8a75] {
  width: 100%; /* Fill the carousel item width */
  /* height: 180%; Fill the carousel item height */
  /* object-fit: cover; Maintain aspect ratio */
}
.CardViewImgBlock img[data-v-3b5a8a75] {
  width: 100%;
  height: 100%;
  /* border-radius: 5px 5px 0px 0px; */
}
.MobilecontentPart[data-v-3b5a8a75] {
  padding: 20px;
  position: relative;
  border-radius: 25px;
  background-color: #f5f3f3;
  top: -28px;
  padding: 1rem;
  height: 31rem;
  overflow: hidden;
  overflow-y: auto;
}
[data-v-3b5a8a75]::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}
.contentPartHeading[data-v-3b5a8a75] {
  font-size: 20px;
  color: #333232;
  font-weight: 600;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.contentPartHeading i[data-v-3b5a8a75] {
  color: #4cad73;
}
.contentPartHeading h5[data-v-3b5a8a75] {
  font-size: 24px;
  color: #333232;
  font-weight: 600;
  margin-top: 15px;
  margin-left: 28px;
  font-weight: 650;
}
.contentPartHeading h6[data-v-3b5a8a75] {
  font-size: 20px;
  color: #4cad73;
}
.contentPartHeading likeBlock[data-v-3b5a8a75] {
  /* remove all the css in it */
  margin-left: 10px;
}
.contentPartLocation[data-v-3b5a8a75] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  margin: left 10rem;
  text-align: start;
  gap: 10px;
  color: #ec8b5e;
  font-weight: 600;
}
.contentPartLocation img[data-v-3b5a8a75] {
  width: 20px;
  height: 20px;
  margin-top: -1rem;
}
.taglines[data-v-3b5a8a75] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 0px;
}
.tagline[data-v-3b5a8a75] {
  font-size: 14px;
  color: #333232;
  font-weight: 600;
  padding: 0%;
}
.tagline p[data-v-3b5a8a75] {
  font-size: 12px;
  color: #4cad73;
  font-weight: 600;
  /* background-color: #4CAD73; add 20% to the color */
  background-color: #b8f3cf;
  padding: 5px;
  border-radius: 5px;
}
.contentPartDivtion[data-v-3b5a8a75] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-top: 10px;
  height: 0px;
  color: #242955;
  font-weight: 600;
}
.contentPartDescription[data-v-3b5a8a75] {
  border-bottom: #333232 1px solid;
  padding: 10px;
}
.contentPartDivtionHeading1[data-v-3b5a8a75] {
  font-weight: 600;
}
.contentPartDivtionHeading1 h4[data-v-3b5a8a75] {
  font-size: 20px;
  color: #333232;
  font-weight: 600;
  font-weight: 650;
}
.contentPartFeatures[data-v-3b5a8a75] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  /* height: 0px; */
  color: #242955;
  font-weight: 600;
}
.promobutton[data-v-3b5a8a75] {
  width: 80%;
  padding: 10px;
  border-radius: 15px;
  background-color: #ff181d;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border: none;
  cursor: pointer;
  /* margin-top: 5rem; */
}
.MobilecontentPartIconsFooter[data-v-3b5a8a75] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 2px;
  background-color: #fff;
  padding: 2rem;
}
.MobilecontentPartIcons[data-v-3b5a8a75] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-bottom: 5px;
  /* margin-top: 2rem; */
  /* background-color: #fff; */
  /* padding: 2rem; */
  /* border-bottom:#242955 1px solid; */
}
.homeIcon[data-v-3b5a8a75] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.homeIconActive[data-v-3b5a8a75] {
  width: 5px;
  height: 5px;
  position: relative;
  left: 0.8rem;
  top: 0.4rem;
}
.homeIconActivebar[data-v-3b5a8a75] {
  width: 155px;
  height: 8px;
  background-color: #242955;
  position: absolute;
  top: 60rem;
  left: 9rem;
  border-radius: 1rem;
}
.homeIconActivebar2[data-v-3b5a8a75] {
  width: 155px;
  height: 5px;
  background-color: #242955;
  position: relative;
  top: -0.6rem;
  left: -13rem;
  border-radius: 1rem;
}
.contentPartFeaturesHeading[data-v-3b5a8a75] {
  /* color: #acacac; */
  text-align: left;
  position: relative;
  /* height: 0; */
}
