.bodyContainer[data-v-f69f57bb] {
  background: url(../../../public/assets/img/bg/Etow-bg.png), linear-gradient(179.97deg, #141a46 50%, #323651 99.97%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  left: 0;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
#logoutBtn[data-v-f69f57bb] {
  background-color: coral;
  /* width: 100%; */
  color: #000;
  border: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  float: right;
  margin-right: 50px;
  cursor: pointer;
}
.inlineContainer[data-v-f69f57bb] {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}