.text22[data-v-bdd881b6]{
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
    -webkit-transform: rotate(-0.34deg);
            transform: rotate(-0.34deg);
}
.card-body1[data-v-bdd881b6] {
    display: flex;
    padding: 22px;
    flex-direction: column;
}
.item[data-v-bdd881b6]{
    background: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 11px;
    border-radius: 7px;
    font-size: large;
    font-weight: 700;
}
#input1[data-v-bdd881b6]{
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 40px;
    padding: 19px;
}
#btn11[data-v-bdd881b6]{
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    position: static;
    top: 0%;
    background: #0085FF;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 10%), 0px 2px 1px rgb(0 0 0 / 6%), 0px 1px 1px rgb(0 0 0 / 8%);
    border-radius: 8px;
    border: none;
    color: white;
}
#card1[data-v-bdd881b6]{
background: #FFFFFF;
border: 3px solid #EC8B5E;
box-sizing: border-box;
box-shadow: 0px 8px 35px rgba(158, 169, 168, 0.65);
border-radius: 38px;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
}
#card2[data-v-bdd881b6]{
background: #FFFFFF;
border: 3px solid #706BEF;
box-sizing: border-box;
box-shadow: 0px 8px 35px rgba(158, 169, 168, 0.65);
border-radius: 38px;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
}
.card22[data-v-bdd881b6]{
    /* position: absolute;
width: 242px;
height: 234px;
left: 420px;
top: 75px; */

background: #FFFFFF;
border: 3px solid #706BEF;
box-sizing: border-box;
box-shadow: 0px 8px 35px rgba(158, 169, 168, 0.65);
border-radius: 38px;
}
.card11[data-v-bdd881b6]{

background: #FFFFFF;
border: 3px solid #EC8B5E;
box-sizing: border-box;
box-shadow: 0px 8px 35px rgba(158, 169, 168, 0.65);
border-radius: 38px;
}
#div1[data-v-bdd881b6]{
    margin: 39px 0px -35px 64px;
}
#div2[data-v-bdd881b6]{
    background: #d2d5ea;
    border-radius: 6px;
}