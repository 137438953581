.active[data-v-3e708e79] {
  color: #000;
}
.card img[data-v-3e708e79] {
  width: 40px;
}
.card[data-v-3e708e79] {
  cursor: pointer;
}
.active-card[data-v-3e708e79] {
  font-weight: bold;
  background: #ec8b5e;
}
.container[data-v-3e708e79] {
  /* width: 1200px; */
  display: grid;
}
.container .card222[data-v-3e708e79] {
  position: relative;
  border-radius: 10px;
  background: #ffffff;
  border: 3px solid #ec8b5e;
  box-sizing: border-box;
  box-shadow: 0px 8px 35px rgb(158 169 168 / 65%);
}
.card15[data-v-3e708e79] {
  background-color: #fff;
  color: #000;
  font-size: 15px;
  padding: 10px;
}
.container .card1[data-v-3e708e79] {
  background: #ffffff;
  height: 90px;
  box-sizing: border-box;
  border-radius: 10px;
}
.container .card2[data-v-3e708e79] {
  background: #ffffff;
  height: 90px;
  box-sizing: border-box;
  border-radius: 10px;
}
.image[data-v-3e708e79] {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fff;
  margin-left: 140px;
  margin-top: -40px;
  position: relative;
  box-shadow: 2px 3px 35px -7px rgba(0, 0, 0, 0.52);
}
.title[data-v-3e708e79] {
  font-weight: 300;
  font-size: 36px;
  line-height: 52px;
  color: #000000;
  text-align: center;
}
.img1[data-v-3e708e79] {
  width: 80px;
  height: 80px;
  margin-left: 5px;
  margin-top: 5px;
}
.text[data-v-3e708e79] {
  text-align: center;
  font-family: sans-serif;
}
h3[data-v-3e708e79] {
  text-transform: uppercase;
  font-size: 23px;
  color: #51c2d5;
  font-weight: 800;
}
p[data-v-3e708e79] {
  font-size: 17px;
  margin-top: -15px;
  color: #5b5b5b;
  font-weight: 600;
}
.follow[data-v-3e708e79] {
  margin: 35px 35px 20px 35px;
}
.follow h2[data-v-3e708e79] {
  display: inline;
  font-family: sans-serif;
  color: #5b5b5b;
  font-size: 35px;
  text-align: center;
}
.heading1[data-v-3e708e79] {
  margin: 21px;
}
.heading2[data-v-3e708e79] {
  margin: 70px 1px 10px 83px;
  padding: 1px;
}
.para[data-v-3e708e79] {
  margin: 5px;
}
.para p[data-v-3e708e79] {
  display: inline;
  /* text-transform: uppercase;
    font-weight: 800;
    font-family: sans-serif;
    color: #51c2d5;
    font-size: 18px; */
}
.info[data-v-3e708e79],
.projects[data-v-3e708e79] {
  margin-bottom: 25px;
}
.info h3[data-v-3e708e79],
.projects h3[data-v-3e708e79] {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  color: #353c4e;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.info_data[data-v-3e708e79],
.projects_data[data-v-3e708e79] {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
  margin-left: 2vh;
}
.info_data .data[data-v-3e708e79],
.projects_data .data[data-v-3e708e79] {
  width: 45%;
}
.info_data .data h4[data-v-3e708e79],
.projects_data .data h4[data-v-3e708e79] {
  color: #353c4e;
  font-size: 17px;
  margin-bottom: 20px;
}
.info_data .data p[data-v-3e708e79],
.projects_data .data p[data-v-3e708e79] {
  font-size: 15px;
  margin-bottom: 10px;
  color: #4a4f54;
}
.p1[data-v-3e708e79] {
  margin: 2px;
  padding: 33px;
}
.push[data-v-3e708e79] {
  background: #51c2d5;
  border-radius: 12px;
  border: none;
  margin: 50px auto 50px 80px;
  text-align: center;
  outline: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 5px;
}
.btn[data-v-3e708e79] {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.2rem;
  background: #8ad9e7;
  color: #3d3b3b;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}
.push:active .btn[data-v-3e708e79] {
  border: none;
  outline: none;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
hr[data-v-3e708e79] {
  border-bottom: 0.2px solid #070707;
  margin: 10px 0;
  width: 72%;
}
.title1[data-v-3e708e79] {
  font-weight: 500;
  font-size: 16px;
  line-height: 52px;
  color: #000000;
  text-align: center;
}
.p12[data-v-3e708e79] {
  font-size: 27px;
  margin-top: -15px;
  color: #5b5b5b;
  font-weight: 500;
}
.text1[data-v-3e708e79] {
  height: 54.61px;
  font-weight: 700;
  font-size: 41px;
  line-height: 25px;
  color: #000000;
  text-align: center;
}
.text12[data-v-3e708e79] {
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  text-align: center;
  color: #4a4a4a;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 20px;
}
.wrapper[data-v-3e708e79] {
  /* margin: auto; */
  /* max-width: 500px; */
  position: relative;
}
form.send input[type="text"][data-v-3e708e79] {
  font-size: 17px;
  width: 100%;
  height: 58px;
  background: #fff;
  color: var(--color-4x);
  border-radius: 8px;
  border: 1px solid var(--color-3x);
  padding-left: 18px;
  padding-right: 60px;
}
form.send input[type="text"][data-v-3e708e79]:focus,
form.send input[type="text"]:focus + i[data-v-3e708e79] {
  color: var(--color-4x);
}
form.send i[data-v-3e708e79] {
  font-size: 24px;
  color: var(--color-3x);
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.span1[data-v-3e708e79] {
  height: 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 21px;
  color: #000000;

  margin-left: 28px;
}
.inputs input[data-v-3e708e79] {
  width: 40px;
  height: 40px;
}
input[data-v-3e708e79] {
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid rgb(50, 85, 87);
  background-color: rgb(50, 85, 87);
  color: #fff;
  border-radius: 10px;
  opacity: 0.4;
}
select[data-v-3e708e79],
option[data-v-3e708e79] {
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid rgb(50, 85, 87);
  background-color: rgb(50, 85, 87);
  color: #fff;
  border-radius: 10px;
  opacity: 0.4;
}
input[value][data-v-3e708e79],
option[data-v-3e708e79] {
  color: #fff;
}

/* Mark input boxes that gets an error on validation: */
input.invalid[data-v-3e708e79] {
  background-color: rgb(50, 85, 87);
}
button[data-v-3e708e79] {
  background-color: coral;
  width: 100%;
  color: #000;
  border: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}
button[data-v-3e708e79]:hover {
  opacity: 0.8;
}
.form-control[data-v-3e708e79] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#prevBtn[data-v-3e708e79] {
  background-color: #bbbbbb;
}
.form-control[data-v-3e708e79] {
  background-color: rgb(30, 30, 51);
  color: #fff;
  border: 2px solid rgb(41, 88, 74);
}
.form-control[data-v-3e708e79]:focus {
  box-shadow: none;
  background-color: rgb(30, 30, 51);
  color: #fff;
  border: 2px solid rgb(27, 65, 68);
}
.validate[data-v-3e708e79] {
  border-radius: 10px;
  padding: 10px;
  background-color: coral;
  border: 1px solid coral;
  width: 100%;
}

/****  floating-Lable style start ****/
.floating-label[data-v-3e708e79] {
  position: relative;
  margin-bottom: 10px;
}
.floating-input[data-v-3e708e79],
.floating-select[data-v-3e708e79] {
  font-size: 14px;
  padding: 20px;
  width: 100%;
  /* height:30px; */
  background-color: rgb(50, 85, 87);
  border: none;
  border: 1px solid rgb(47, 69, 87);
}
.floating-input[data-v-3e708e79]:focus,
.floating-select[data-v-3e708e79]:focus {
  outline: none;
  /* border-bottom:2px solid #5264AE;  */
}
label[data-v-3e708e79] {
  color: #000;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 20px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.floating-input:not(:-ms-input-placeholder) ~ label[data-v-3e708e79] {
  top: 9px;
  font-size: 14px;
  color: #f9faff;
}
.floating-input:focus ~ label[data-v-3e708e79],
.floating-input:not(:placeholder-shown) ~ label[data-v-3e708e79] {
  top: 9px;
  font-size: 14px;
  color: #f9faff;
}
.floating-select:focus ~ label[data-v-3e708e79],
.floating-select:not([value]):valid ~ label[data-v-3e708e79] {
  top: 9px;
  font-size: 14px;
  color: #f9faff;
}

/* active state */
.floating-input:focus ~ .bar[data-v-3e708e79]:before,
.floating-input:focus ~ .bar[data-v-3e708e79]:after,
.floating-select:focus ~ .bar[data-v-3e708e79]:before,
.floating-select:focus ~ .bar[data-v-3e708e79]:after {
  width: 100%;
}
*[data-v-3e708e79],
*[data-v-3e708e79]:before,
*[data-v-3e708e79]:after {
  box-sizing: border-box;
}
.floating-textarea[data-v-3e708e79] {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
}
.floating-label[data-v-3e708e79] {
  position: relative;
  margin-bottom: 10px;
}

/* highlighter */
.highlight[data-v-3e708e79] {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.floating-input:focus ~ .highlight[data-v-3e708e79],
.floating-select:focus ~ .highlight[data-v-3e708e79] {
  -webkit-animation: inputHighlighter-data-v-3e708e79 0.3s ease;
  animation: inputHighlighter-data-v-3e708e79 0.3s ease;
}

/* animation */
@-webkit-keyframes inputHighlighter-data-v-3e708e79 {
from {
    background: #5264ae;
}
to {
    width: 0;
    background: transparent;
}
}
@keyframes inputHighlighter-data-v-3e708e79 {
from {
    background: #5264ae;
}
to {
    width: 0;
    background: transparent;
}
}
.apply[data-v-3e708e79] {
  background-color: coral;
  color: #000;
  border-radius: 10px;
  padding: 21px;
  margin-left: -29%;
  /* position: absolute;
  right: 36px;
  top: 39.5%; */
}
.promocode[data-v-3e708e79]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
}
.promocode[data-v-3e708e79]::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
}
.promocode[data-v-3e708e79]:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
}
.promocode[data-v-3e708e79]:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
}
.ui-datepicker-calendar[data-v-3e708e79] {
  display: none;
}
.label2[data-v-3e708e79] {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}
.label2 input[data-v-3e708e79] {
  position: absolute;
  left: -9999px;
}
.label2 input:checked + span[data-v-3e708e79] {
  background-color: #d6d6e5;
}
.label2 input:checked + span[data-v-3e708e79]:before {
  box-shadow: inset 0 0 0 0.4375em #00005c;
}
.label2 span[data-v-3e708e79] {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
}
.label2 span[data-v-3e708e79]:hover {
  background-color: #d6d6e5;
}
.label2 span[data-v-3e708e79]:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #00005c;
}
.active[data-v-3e708e79] {
  color: #000;
}
/* card */
.card img[data-v-3e708e79] {
  width: 40px;
}
.card[data-v-3e708e79] {
  /* cursor: pointer; */
  cursor: pointer;
  border: 0.5px solid #cccccc;
  border-radius: 10px;
  height: 0px !important;
  width: 100%;
}
.active-card[data-v-3e708e79] {
  /* color: #763cb0; */
  font-weight: bold;
  background: #ec8b5e;
}

/* label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
  } */
/* label input {
    position: absolute;
    left: -9999px;
  }
  label input:checked + span {
    background-color: #d6d6e5;
  }
  label input:checked + span:before {
    box-shadow: inset 0 0 0 0.4375em #00005c;
  }
  label span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
  }
  label span:hover {
    background-color: #d6d6e5;
  }
  label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #00005c;
  } */
#discountInput[data-v-3e708e79] {
  padding: 30px !important;
  border-radius: 10px 0px 0px 10px !important;
}
