*[data-v-60c2f07a] {
  box-sizing: border-box;
}
body[data-v-60c2f07a] {
  background-color: rgb(30, 30, 51);
  color: #fff;
  font-family: Raleway;
}
#regForm[data-v-60c2f07a] {
  margin: 50px auto;
  font-family: Raleway;
  padding: 20px;
  width: 100%;
  min-width: 300px;
}
.height-100[data-v-60c2f07a] {
  height: 100vh;
}
.card[data-v-60c2f07a] {
  /* width: 400px;
  border: none;
  height: 300px;
  box-shadow: 0px 5px 20px 0px #d2dae3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center */
  background-color: rgb(30, 55, 75);
}
.card h6[data-v-60c2f07a] {
  color: rgb(30, 55, 75);
  font-size: 20px;
}
.textdata[data-v-60c2f07a]:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.textdata[data-v-60c2f07a] {
  /* transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06); */
  background-color: red;
}
.inputs input[data-v-60c2f07a] {
  width: 40px;
  height: 40px;
}
input[type="number"][data-v-60c2f07a]::-webkit-inner-spin-button,
input[type="number"][data-v-60c2f07a]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.card-2[data-v-60c2f07a] {
  background-color: #fff;
  padding: 10px;
  width: 350px;
  height: 100px;
  bottom: -50px;
  left: 20px;
  position: absolute;
  border-radius: 5px;
}
.card-2 .content[data-v-60c2f07a] {
  margin-top: 50px;
}
.card-2 .content a[data-v-60c2f07a] {
  color: rgb(30, 55, 75);
}
.form-control[data-v-60c2f07a] {
  background-color: rgb(30, 30, 51);
  color: #fff;
  border: 2px solid rgb(41, 88, 74);
}
.form-control[data-v-60c2f07a]:focus {
  box-shadow: none;
  background-color: rgb(30, 30, 51);
  color: #fff;
  border: 2px solid rgb(27, 65, 68);
}
.validate[data-v-60c2f07a] {
  border-radius: 10px;
  padding: 10px;
  background-color: coral;
  border: 1px solid coral;
  width: 100%;
}
.floating-form[data-v-60c2f07a] {
  /* width:320px; */
}

/****  floating-Lable style start ****/
.floating-label[data-v-60c2f07a] {
  position: relative;
  margin-bottom: 10px;
}
.floating-input[data-v-60c2f07a],
.floating-select[data-v-60c2f07a] {
  font-size: 14px;
  padding: 20px;
  width: 100%;
  /* height:30px; */
  background-color: rgb(50, 85, 87);
  border: none;
  border: 1px solid rgb(47, 69, 87);
}
.floating-input[data-v-60c2f07a]:focus,
.floating-select[data-v-60c2f07a]:focus {
  outline: none;
  /* border-bottom:2px solid #5264AE;  */
}
label[data-v-60c2f07a] {
  color: #000;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 20px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.floating-input:not(:-ms-input-placeholder) ~ label[data-v-60c2f07a] {
  top: 9px;
  font-size: 14px;
  color: #f9faff;
}
.floating-input:focus ~ label[data-v-60c2f07a],
.floating-input:not(:placeholder-shown) ~ label[data-v-60c2f07a] {
  top: 9px;
  font-size: 14px;
  color: #f9faff;
}
.floating-select:focus ~ label[data-v-60c2f07a],
.floating-select:not([value]):valid ~ label[data-v-60c2f07a] {
  top: 9px;
  font-size: 14px;
  color: #f9faff;
}

/* active state */
.floating-input:focus ~ .bar[data-v-60c2f07a]:before,
.floating-input:focus ~ .bar[data-v-60c2f07a]:after,
.floating-select:focus ~ .bar[data-v-60c2f07a]:before,
.floating-select:focus ~ .bar[data-v-60c2f07a]:after {
  width: 100%;
}
*[data-v-60c2f07a],
*[data-v-60c2f07a]:before,
*[data-v-60c2f07a]:after {
  box-sizing: border-box;
}
.floating-textarea[data-v-60c2f07a] {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
}

/* highlighter */
.highlight[data-v-60c2f07a] {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.floating-input:focus ~ .highlight[data-v-60c2f07a],
.floating-select:focus ~ .highlight[data-v-60c2f07a] {
  -webkit-animation: inputHighlighter-data-v-60c2f07a 0.3s ease;
  animation: inputHighlighter-data-v-60c2f07a 0.3s ease;
}

/* animation */
@-webkit-keyframes inputHighlighter-data-v-60c2f07a {
from {
    background: #5264ae;
}
to {
    width: 0;
    background: transparent;
}
}
@keyframes inputHighlighter-data-v-60c2f07a {
from {
    background: #5264ae;
}
to {
    width: 0;
    background: transparent;
}
}
.apply[data-v-60c2f07a] {
  background-color: coral;
  color: #000;
  border-radius: 10px;
  padding: 21px;
  margin-left: -29%;
  /* position: absolute;
  right: 36px;
  top: 39.5%; */
}
.promocode[data-v-60c2f07a]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
}
.promocode[data-v-60c2f07a]::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
}
.promocode[data-v-60c2f07a]:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
}
.promocode[data-v-60c2f07a]:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
}
.ui-datepicker-calendar[data-v-60c2f07a] {
  display: none;
}
