.container[data-v-36a8de6f] {
  /* width: 1200px; */
  display: grid;
}
.container .card[data-v-36a8de6f] {
  position: relative;
  width: 370px;
  /* height: 389px; */
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 3px 35px -7px rgba(0, 0, 0, 0.52);
}
.container .card1[data-v-36a8de6f] {
  position: relative;
  max-width: 370px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 3px 35px -7px rgba(0, 0, 0, 0.52);
}
.image[data-v-36a8de6f] {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fff;
  margin-left: 140px;
  margin-top: -40px;
  position: relative;
  box-shadow: 2px 3px 35px -7px rgba(0, 0, 0, 0.52);
}
.title[data-v-36a8de6f] {
  font-weight: 300;
  font-size: 36px;
  line-height: 52px;
  color: #000000;
  text-align: center;
}
.img1[data-v-36a8de6f] {
  width: 80px;
  height: 80px;
  margin-left: 5px;
  margin-top: 5px;
  border-radius:50px;
}
.text[data-v-36a8de6f] {
  text-align: center;
  font-family: sans-serif;
}
h3[data-v-36a8de6f] {
  text-transform: uppercase;
  font-size: 23px;
  color: #51c2d5;
  font-weight: 800;
}
p[data-v-36a8de6f] {
  font-size: 17px;
  margin-top: -15px;
  color: #5b5b5b;
  font-weight: 600;
}
.follow[data-v-36a8de6f] {
  margin: 35px 35px 20px 35px;
}
.follow h2[data-v-36a8de6f] {
  display: inline;
  font-family: sans-serif;
  color: #5b5b5b;
  font-size: 35px;
  text-align: center;
}
.heading1[data-v-36a8de6f] {
  margin: 21px;
}
.heading2[data-v-36a8de6f] {
  margin: 70px 1px 10px 83px;
  padding: 1px;
}
.para[data-v-36a8de6f] {
  margin: 5px;
}
.para p[data-v-36a8de6f] {
  display: inline;
  /* text-transform: uppercase;
    font-weight: 800;
    font-family: sans-serif;
    color: #51c2d5;
    font-size: 18px; */
}
.info[data-v-36a8de6f],
.projects[data-v-36a8de6f] {
  margin-bottom: 25px;
}
.info h3[data-v-36a8de6f],
.projects h3[data-v-36a8de6f] {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  color: #353c4e;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.info_data[data-v-36a8de6f],
.projects_data[data-v-36a8de6f] {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
  margin-left: 2vh;
}
.info_data .data[data-v-36a8de6f],
.projects_data .data[data-v-36a8de6f] {
  width: 45%;
}
.info_data .data h4[data-v-36a8de6f],
.projects_data .data h4[data-v-36a8de6f] {
  color: #353c4e;
  font-size: 17px;
  margin-bottom: 20px;
}
.info_data .data p[data-v-36a8de6f],
.projects_data .data p[data-v-36a8de6f] {
  font-size: 15px;
  margin-bottom: 10px;
  color: #4a4f54;
}
.p1[data-v-36a8de6f] {
  margin: 2px;
  padding: 33px;
}
.push[data-v-36a8de6f] {
  background: #51c2d5;
  border-radius: 12px;
  border: none;
  margin: 50px auto 50px 80px;
  text-align: center;
  outline: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 5px;
}
.btn[data-v-36a8de6f] {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.2rem;
  background: #8ad9e7;
  color: #3d3b3b;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}
.push:active .btn[data-v-36a8de6f] {
  border: none;
  outline: none;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
#vertical[data-v-36a8de6f] {
  width: 0.1px;
  height: 52px;
  background-color: #000000;
}
.img12[data-v-36a8de6f] {
  height: 22px;
  margin-left: 327px;
  margin-top: -58px;
}
#vertical1[data-v-36a8de6f] {
  width: 0.1px;
  height: 62px;
  background-color: #000000;
  margin-top: 9px;
}
.title1[data-v-36a8de6f] {
  font-weight: 500;
  font-size: 16px;
  line-height: 52px;
  color: #000000;
  text-align: center;
}
.p12[data-v-36a8de6f] {
  font-size: 27px;
  margin-top: -15px;
  color: #5b5b5b;
  font-weight: 500;
}
.btn1[data-v-36a8de6f] {
  height: 55px;
  margin: 13px 18px 28px 13px;
  background: #ec8b5e;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
  border-radius: 10px;
}
.div1[data-v-36a8de6f] {
  text-align: center;
  padding: 14px;
  color: black;
  font-weight: 500;
}
#updateFormBtn[data-v-36a8de6f], #proceedFormBtn[data-v-36a8de6f] {
  background-color: coral;
  color: #4A4A4A;
  border: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  float: right;
  cursor: pointer;
  width: 100%;
}
#removeVehicleBtn[data-v-36a8de6f] {
  background-color: #BE1E2D;
  color: #fff;
  border: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  float: right;
  cursor: pointer;
  width: 100%;
}
#removeVehicleCancel[data-v-36a8de6f] {
  background-color: #fff;
  color: #000;
  border: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  float: right;
  cursor: pointer;
  width: 100%;
}
.borderRadiusZero[data-v-36a8de6f] {
  border-radius: 0px !important;
  font-weight: bold;
}
.boldText[data-v-36a8de6f] {
  font-weight: bold;
  color: #000 !important;
}
