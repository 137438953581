hr[data-v-d0f24a91]{
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    width: 100%;
}
.text1[data-v-d0f24a91]{
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #9FA2B4;
}
.text2[data-v-d0f24a91]{
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #252733;
}
#border[data-v-d0f24a91]{
    height: -webkit-min-content;
    height: min-content;
    border-top-width: 1px;
    border-left-style: outset;
    margin-top: -63px;
}
.displayflex[data-v-d0f24a91] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
}
#h4[data-v-d0f24a91]{
    text-align: left;
}
#div1[data-v-d0f24a91]{
    text-align: left;
    margin: 51px 0px 0px 40px;
}
#img1[data-v-d0f24a91]{
    width: 373px;
}
.div2[data-v-d0f24a91]{
    padding: 15px;
}