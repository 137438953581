.card-body[data-v-2185844e] {
    display: flex;
    flex-direction: column;
}
.text1[data-v-2185844e]{
    font-style: normal;
    font-weight: 500;
    color: #252733;
}
/* .text2{
    color: #9FA2B4;
} */
.card1-right[data-v-2185844e]{
    float:right;
    right: 20%;
    top:10%;
}
.card1-left[data-v-2185844e]{
    float: left;
    left: 20%;
    top:10%;
}
.card1-right .checkbox[data-v-2185844e]{
    display: none;
}
.card1-right .label[data-v-2185844e]{
    position: relative;
    font-family:sans-serif;
    display: block;
    padding-left: 60px;
}
.card1-right .check-mark[data-v-2185844e]{
    width: 30px;
    height: 30px;
    position: absolute;
    left:0;
    display: inline-block;
    top: 0;
    border-radius: 3px;
}
.card1-right .label .checkbox:checked + .check-mark[data-v-2185844e]{
    background-color: #00116A  ;
    transition: .1s;
}
.card1-right .label .checkbox:checked + .check-mark[data-v-2185844e]:after{
    content: "";
    position: absolute;
    width: 10px;
      transition: .1s;
    height: 5px;
    background: #00116A   ;
    top:45%;
    left:50%;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
}
.card1-left .checkbox[data-v-2185844e]{
    display: none;
}
.card1-left .label[data-v-2185844e]{
    position: relative;
    font-family:sans-serif;
    display: block;
    padding-left: 46px;
}
.card1-left .check-mark[data-v-2185844e]{
    width: 30px;
    height: 30px;
    position: absolute;
    left:0;
    display: inline-block;
    top: 0;
    border-radius: 50%;
    border: 2.2px solid darkgray;
}
.card1-left .label .checkbox:checked + .check-mark[data-v-2185844e]{
    background-color: #2116fac4  ;
    transition: .1s;
}
.card1-left .label .checkbox:checked + .check-mark[data-v-2185844e]:after{
    content: "";
    position: absolute;
    width: 10px;
    transition: .1s;
    height: 5px;
    top:45%;
    left:50%;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
}
#div1[data-v-2185844e]{
    margin-top: 51vh;
}
#h1[data-v-2185844e]{
    color: #3619c9;
}
#h2[data-v-2185844e]{
    color:darkgrey
}
#div2[data-v-2185844e]{
    text-align: left;
}
#span1[data-v-2185844e]{
    color: black;
}
#div3[data-v-2185844e]{
    position: absolute;
    width: 73px;
    height: 24px;
    right: 32px;
    top: calc(50% - 24px/2);
    background: #FEC400;
    border-radius: 8px;
    color: #9FA2B4;
}
#div4[data-v-2185844e]{
    width: 73px;
    height: 24px;
    right: 32px;
    top: calc(50% - 24px/2);
    background: #49fb90;
    border-radius: 8px;
}
#div5[data-v-2185844e]{
    padding: 6px;
    background: #F0F1F7;
    border-radius: 8px;
}

/* 
  #h3{
    color: #3619c9;
  } */