.p-30[data-v-7d2221b5]{
    padding:30px;
}
.main-datatable[data-v-7d2221b5] {
    padding: 0px; 
    border: 1px solid #f3f2f2;
    border-bottom: 0;
    box-shadow: 0px 2px 10px rgba(0,0,0,.05);
}
.d-flex[data-v-7d2221b5]{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card_body[data-v-7d2221b5]{ 
    background-color: white;
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.main-datatable .row[data-v-7d2221b5] {
    margin: 0;
}
.searchInput[data-v-7d2221b5] {
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    margin: 20px 0px;
    padding: 0px 4px;
}
.searchInput input[data-v-7d2221b5] {
    border: 1px solid #e5e5e5;
    border-radius: 50px;
    margin-left: 8px;
    height: 34px;
    width: 100%;
    padding: 0px 25px 0px 10px;
    transition: all .6s ease;
}
.searchInput label[data-v-7d2221b5] {
    color: #767676;
    font-weight: normal;
}
.searchInput input[data-v-7d2221b5]:-ms-input-placeholder {
    width: 13%;
}
.searchInput input[data-v-7d2221b5]:placeholder-shown {
    width: 13%;
}
.searchInput:hover input[data-v-7d2221b5]:-ms-input-placeholder {
    width: 100%;
    cursor: pointer;
}
.searchInput:hover input[data-v-7d2221b5]:placeholder-shown {
    width: 100%;
    cursor: pointer;
}
.searchInput[data-v-7d2221b5]:after {
    font-family: 'FontAwesome';
    color: #d4d4d4;
    position: relative;
    content: "\f002";
    right: 25px;
}
.dim_button[data-v-7d2221b5] {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding-top: 6px;
    background: rgb(57, 85, 136);
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 20px;
}
.createSegment a[data-v-7d2221b5] { 
    margin-bottom: 0px;
    border-radius: 50px;
    background: #ffffff;
    border: 1px solid #007bff;
    color: #007bff;
    transition: all .4s ease;
}
.createSegment a[data-v-7d2221b5]:hover, .createSegment a[data-v-7d2221b5]:focus {
    transition: all .4s ease;
    background: #007bff;
    color: #fff;
}
.add_flex[data-v-7d2221b5]{
    display: flex;
    justify-content: flex-end;
    padding-right:0px;
}
.main-datatable .dataTable.no-footer[data-v-7d2221b5] {
    border-bottom: 1px solid #eee;
}
.main-datatable .cust-datatable thead[data-v-7d2221b5] {
    background-color: #f9f9f9;
}
.main-datatable .cust-datatable>thead>tr>th[data-v-7d2221b5] {
    border-bottom-width: 0;
    color: #443f3f;
    font-weight: 600;
    padding: 16px 15px;
    vertical-align: middle;
    padding-left: 18px;
    text-align: center;
}
.main-datatable .cust-datatable>tbody td[data-v-7d2221b5] {
    padding: 10px 15px 10px 18px;
    color: #333232;
    font-size: 13px;
    font-weight: 500;
    word-break: break-word;
    border-color: #eee;
    text-align: center;
    vertical-align: middle;
}
.main-datatable .cust-datatable>tbody tr[data-v-7d2221b5] {
    border-top: none;
}
.main-datatable .table > tbody > tr[data-v-7d2221b5]:nth-child(even) {
    background: #f9f9f9;
}
.btn-group.open .dropdown-toggle[data-v-7d2221b5] {
    box-shadow: none;
}
.main-datatable .dropdown_icon[data-v-7d2221b5] {
    display: inline-block;
    color: #8a8a8a;
    font-size: 12px;
    border: 1px solid #d4d4d4;
    padding: 10px 11px;
    border-radius: 50%;
    cursor: pointer;
}
.btn-group i[data-v-7d2221b5]{
    color: #8e8e8e; 
    margin: 2px;
}
.main-datatable .actionCust a[data-v-7d2221b5] {
    display: inline-block;
    color: #8a8a8a;
    font-size: 12px;
    border: 1px solid #d4d4d4;
    padding: 10px 11px;
    margin: -9px 3px;
    border-radius: 50%;
    cursor: pointer;
}
.main-datatable .actionCust a i[data-v-7d2221b5]{
    color: #8e8e8e;
    margin: 2px;
}
.main-datatable .dropdown-menu[data-v-7d2221b5] {
    padding: 0;
    border-radius: 4px;
    box-shadow: 10px 10px 20px #c8c8c8;
    margin-top: 10px;
    left: -65px;
    top: 32px;
}
.main-datatable .dropdown-menu > li > a[data-v-7d2221b5] {
    display: block;
    padding: 12px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
    border-bottom: 1px solid #d4d4d4;
}
.main-datatable .dropdown-menu > li > a[data-v-7d2221b5]:hover, 
.main-datatable .dropdown-menu > li > a[data-v-7d2221b5]:focus {
    color: #fff;
    background: #007bff;
}
.main-datatable .dropdown-menu > li > a:hover i[data-v-7d2221b5]{
    color: #fff;
}
.main-datatable .dropdown-menu[data-v-7d2221b5]:before {
    position: absolute;
    top: -7px;
    left: 78px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #d4d4d4;
    border-left: 7px solid transparent;
    border-bottom-color: #d4d4d4;
    content: '';
}
.main-datatable .dropdown-menu[data-v-7d2221b5]:after {
    position: absolute;
    top: -6px;
    left: 78px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}
.dropdown-menu i[data-v-7d2221b5] {
    margin-right: 8px;
}
.main-datatable .dataTables_wrapper .dataTables_paginate .paginate_button[data-v-7d2221b5] {
    color: #999999 !important;
    background-color: #f6f6f6 !important;
    border-color: #d4d4d4 !important;
    border-radius: 40px;
    margin: 5px 3px;
}
.main-datatable .dataTables_wrapper .dataTables_paginate .paginate_button[data-v-7d2221b5]:hover {
    color: #fff !important;
    border: 1px solid #3d96f5 !important;
    background: #4da3ff !important;
    box-shadow: none;
}
.main-datatable .dataTables_wrapper .dataTables_paginate .paginate_button.current[data-v-7d2221b5], 
.main-datatable .dataTables_wrapper .dataTables_paginate .paginate_button.current[data-v-7d2221b5]:hover {
    color: #fff !important;
    border-color: transparent !important;
    background: #007bff !important;
}
.main-datatable .dataTables_paginate[data-v-7d2221b5] {
    padding-top: 0 !important;
    margin: 15px 10px;
    float: right !important;
}
.mode[data-v-7d2221b5]{
    padding:4px 10px;
    line-height: 13px;
    color:#fff;
    font-weight: 400;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    font-size:11px;
    letter-spacing: 0.4px;
}
.mode_on[data-v-7d2221b5]{
    background-color: #09922d;
}
.mode_off[data-v-7d2221b5]{
    background-color: #8b9096;
}
.mode_process[data-v-7d2221b5]{
    background-color: #ff8000;
}
.mode_done[data-v-7d2221b5]{
    background-color: #03a9f3;
}
@media only screen and (max-width:1200px){
.overflow-x[data-v-7d2221b5]{
        overflow-x:scroll;
}
.overflow-x[data-v-7d2221b5]::-webkit-scrollbar{
        width:5px;
        height:6px;
}
.overflow-x[data-v-7d2221b5]::-webkit-scrollbar-thumb{
        background-color: #888;
}
.overflow-x[data-v-7d2221b5]::-webkit-scrollbar-track{
         background-color: #f1f1f1;
}
}
#div1[data-v-7d2221b5]{
    text-align: left;
    display: flex;
    justify-content: space-between;
}
#div2[data-v-7d2221b5]{
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 0.4px;
}
#div3[data-v-7d2221b5]{
    text-align: left;
}
#div4[data-v-7d2221b5]{
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 0.4px;
}
#img11[data-v-7d2221b5]{
    width: auto;
}
label[data-v-7d2221b5]{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.4px;
    text-align: left;
}
.form-group[data-v-7d2221b5] {
    text-align: left;
}
.custom-input[data-v-7d2221b5] {
    background-color: #f9f9f9;
    padding: 10px; 
    display: flex;
    border: none;
    width: 100%;
    border-radius: 5px;
}
.custom-input input[data-v-7d2221b5] {
        border: none;
        background-color: #f9f9f9;
        width: 100%;
        padding: 10px;
}
.custom-input input[data-v-7d2221b5]:focus {
        outline: none;
}
.form-check-input[data-v-7d2221b5] {
        width: 20px;
        height: 20px;
        right: 10px;
        border-radius: 50%;
}
.form-check-label[data-v-7d2221b5] {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.4px;
}
.form-check[data-v-7d2221b5] {
        margin-left: 0px;
        padding: 10px;
        background-color: #f9f9f9;
        margin: 10px 0px;
        width: 100%;
}
.image-uploadBlock[data-v-7d2221b5] {
        justify-content: space-between;
        align-items: center;
        
        justify-content: center;
}
.image-upload[data-v-7d2221b5]{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        /* padding: 26% 19% 10% 20%; */
        border: 1px solid #f9f9f9;
        border-radius: 5px;
        background-color: #f9f9f9;
        cursor: pointer;
        flex-direction: column;
        gap: 5%;
        padding-bottom: 18%;
        padding-top: 14%;
}
.hidden[data-v-7d2221b5] {
    display: none;
}
.imageUploadElements[data-v-7d2221b5]{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 5%;
}
.imageUploadElements img[data-v-7d2221b5]{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.imageUploadElements i[data-v-7d2221b5]{
    position: absolute;
    right: 0;
    top: 0;
    background-color: #f9f9f9;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}
.imageUploadElements i[data-v-7d2221b5]:hover{
    background-color: #f9f9f9;
}

/* make the checkbox in radius bit circle */
.form-check-input[data-v-7d2221b5] {
    width: 20px;
    height: 20px;
    right: 10px;
    border-radius: 50%;
}
.actionBtns[data-v-7d2221b5]{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}