table[data-v-f323a952] {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
table *[data-v-f323a952] {
    position: relative;
}
table td[data-v-f323a952], table th[data-v-f323a952] {
    padding-left: 8px;
}
table thead tr[data-v-f323a952] {
    height: 60px;
    background: #ffffff;
    font-size: 16px;
}
table tbody tr[data-v-f323a952] {
    height: 48px;
    border-bottom: 1px solid #E3F1D5;
}
table tbody tr[data-v-f323a952]:last-child {
    border: 0;
}
table td[data-v-f323a952], table th[data-v-f323a952] {
    text-align: left;
}
table td.l[data-v-f323a952], table th.l[data-v-f323a952] {
    text-align: right;
}
table td.c[data-v-f323a952], table th.c[data-v-f323a952] {
    text-align: center;
}
table td.r[data-v-f323a952], table th.r[data-v-f323a952] {
    text-align: center;
}
@media screen and (max-width: 35.5em) {
table[data-v-f323a952] {
      display: block;
}
table > *[data-v-f323a952], table tr[data-v-f323a952], table td[data-v-f323a952], table th[data-v-f323a952] {
      display: block;
}
table thead[data-v-f323a952] {
      display: none;
}
table tbody tr[data-v-f323a952] {
      height: auto;
      padding: 8px 0;
}
table tbody tr td[data-v-f323a952] {
      padding-left: 45%;
      margin-bottom: 12px;
}
table tbody tr td[data-v-f323a952]:last-child {
      margin-bottom: 0;
}
table tbody tr td[data-v-f323a952]:before {
      position: absolute;
      font-weight: 700;
      width: 40%;
      left: 10px;
      top: 0;
}
table tbody tr td[data-v-f323a952]:nth-child(1):before {
      content: "Code";
}
table tbody tr td[data-v-f323a952]:nth-child(2):before {
      content: "Stock";
}
table tbody tr td[data-v-f323a952]:nth-child(3):before {
      content: "Cap";
}
table tbody tr td[data-v-f323a952]:nth-child(4):before {
      content: "Inch";
}
table tbody tr td[data-v-f323a952]:nth-child(5):before {
      content: "Box Type";
}
}
blockquote[data-v-f323a952] {
    color: white;
    text-align: center;
}
hr[data-v-f323a952]{
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    width: 100%;
}
#nextbtn[data-v-f323a952]{

    width: 34%;
    color: #000;
    border: none;
    padding: 10px 20px;
    font-size: 17px;
    font-family: Raleway;
    cursor: pointer;
    background: #EC8B5E;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
    border-radius: 30px;
    font-size: medium;
    font-family: 'Roboto';
}
#text21[data-v-f323a952]{
    font-family: Avenir;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 25px;
display: flex;
align-items: flex-end;
text-align: center;
letter-spacing: -0.02em;

color: #000000;
}
.text22[data-v-f323a952]{
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
    -webkit-transform: rotate(-0.34deg);
            transform: rotate(-0.34deg);
}
#div1[data-v-f323a952]{
  text-align: left;
}
#span1[data-v-f323a952]{
  margin: 42px 0px 0px 23px;
}
#th1[data-v-f323a952]{
  color: #4662f1;
}
#span2[data-v-f323a952]{
  color: rgb(0, 145, 41);
}
#span3[data-v-f323a952]{
  color: red;
}
#span4[data-v-f323a952]{
  color: rgb(0, 145, 41);
}
#span5[data-v-f323a952]{
  color: rgb(0, 145, 41);
}
.div2[data-v-f323a952]{
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 65px;
}
#div3[data-v-f323a952]{
  margin-left: 11px;
}
.priceInput[data-v-f323a952] {
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 65px;
  text-align: center;
}