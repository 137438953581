@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*[data-v-60c2f07a] {
  box-sizing: border-box;
}
h5[data-v-60c2f07a],
h5[data-v-60c2f07a] {
  font-size: 1.25rem;
  font-family: Raleway;
}
body[data-v-60c2f07a] {
  background-color: rgb(30, 30, 51);
  color: #fff;
}
#regForm[data-v-60c2f07a] {
  margin: 50px auto;
  font-family: Raleway;
  padding: 20px;
  width: 100%;
  min-width: 300px;
}
h1[data-v-60c2f07a] {
  text-align: left;
}
.inputs input[data-v-60c2f07a] {
  width: 40px;
  height: 40px;
}
input[data-v-60c2f07a] {
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid rgb(50, 85, 87);
  background-color: rgb(50, 85, 87);
  color: #fff;
  border-radius: 10px;
  opacity: 0.4;
}
select[data-v-60c2f07a],
option[data-v-60c2f07a] {
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid rgb(50, 85, 87);
  background-color: rgb(50, 85, 87);
  color: #fff;
  border-radius: 10px;
  opacity: 0.4;
}
input[value][data-v-60c2f07a],
option[data-v-60c2f07a] {
  color: #fff;
}

/* Mark input boxes that gets an error on validation: */
input.invalid[data-v-60c2f07a] {
  background-color: rgb(50, 85, 87);
}

/* Hide all steps by default: */
/* .tab {
  display: none;
} */
button[data-v-60c2f07a] {
  background-color: coral;
  width: 100%;
  color: #000;
  border: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}
button[data-v-60c2f07a]:hover {
  opacity: 0.8;
}
#prevBtn[data-v-60c2f07a] {
  background-color: #bbbbbb;
}

/* Make circles that indicate the steps of the form: */
.step[data-v-60c2f07a] {
  height: 5px;
  width: 50px;
  margin: 0 2px;
  background-color: #dbdad8;
  border: none;
  border-radius: 10px;
  display: inline-block;
  opacity: 0.5;
}
.step.active[data-v-60c2f07a] {
  opacity: 1;
  background-color: coral;
}

/* Mark the steps that are finished and valid: */
.step.finish[data-v-60c2f07a] {
  background-color: #dbdad8;
}
form i[data-v-60c2f07a] {
  margin-left: -30px;
  cursor: pointer;
}
