.displayflex[data-v-60c3aca4]{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
    /* font-weight: 600; */
}
#h6[data-v-60c3aca4]{
    color: #765afb;
}
#div1[data-v-60c3aca4]{
    color: darkorchid;
}
#span1[data-v-60c3aca4]{
    color: darkgreen;
}
  
