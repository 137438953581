/* @keyframes chartjs-render-animation{from{opacity:.99}to{opacity:1}}
	.chartjs-render-monitor{animation:chartjs-render-animation 1ms}
	.chartjs-size-monitor,.chartjs-size-monitor-expand,.chartjs-size-monitor-shrink{position:absolute;direction:ltr;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1}
	.chartjs-size-monitor-expand>div{position:absolute;width:1000000px;height:1000000px;left:0;top:0}
	.chartjs-size-monitor-shrink>div{position:absolute;width:200%;height:200%;left:0;top:0} */
#position[data-v-34e020cf]{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
}
#text11[data-v-34e020cf]{
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        color: #969696;
        margin: 0px 0px;
}
#text12[data-v-34e020cf]{
        color: #1C1C1C;
        flex: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 48px;
}
#text13[data-v-34e020cf]{
        background: linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #FFFFFF;
        border-radius: 6px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #00BA34;
}
.button1[data-v-34e020cf] {
        /* padding: 15px 25px;
        font-size: 24px;
        text-align: center;
        cursor: pointer;
        outline: none;
        color: rgb(0, 0, 0);
        background-color: #ffffff;
        border: none;
        border-radius: 8px; */
    
    
        font-size: 24px;
        text-align: center;
        cursor: pointer;
        outline: none;
        color: rgb(0, 0, 0);
        background-color: #ffffff;
        border: none;
        border-radius: 8px;
        height: -webkit-max-content;
        height: max-content;
        padding: 0PX 22px;
}
.button1.button1[data-v-34e020cf]:hover {background-color: #3e8e41}
.button1.button1.button1[data-v-34e020cf]:hover {
        background-color: #0085FF;
        color: rgb(251, 251, 251);
        border-radius: 8px;
}
.input1[data-v-34e020cf]{
        border-radius: 10px;
        border: none;
        outline: none;
        margin: 45px 0px 4px 0px;
        text-align: center;
        width: 141px;
        font-size: 24px;
}
.input1.input1[data-v-34e020cf]:hover{
        background-color: #0085FF;
        color: rgb(251, 251, 251);
        border-radius: 8px;
}
.datepicker-section[data-v-34e020cf]{
        margin: auto;
}
#div1[data-v-34e020cf]{
          display: flex;
          justify-content: space-between;
          align-content: stretch;
          flex-direction: row-reverse;
}
.p1[data-v-34e020cf]{
          color: darkgrey;
}
.canvas[data-v-34e020cf]{
          display: block; width: 1379px; height: 689px;
}
#div2[data-v-34e020cf]{
          display: flex;
          justify-content: space-between;
          align-content: stretch;
          flex-direction: row-reverse;
}
.div3[data-v-34e020cf]{
          width:85%;height:200px;margin:2% auto 0 auto
}
#div4[data-v-34e020cf]{
          display: flex;
          justify-content: space-between;
          align-content: stretch;
          flex-direction: row-reverse;
}
.div5[data-v-34e020cf]{
          width:85%;
          height:200px;
          margin:2% auto 0 auto;
}
    
        