  /*login form css*/
*[data-v-9116f47d]{
    box-sizing: content-box;
}
.form[data-v-9116f47d]{
    font-family: "Roboto", sans-serif;
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    opacity: 99%;
    max-width: 260px;
    /* height: 477px; */
    /* width: 264px;  */
    margin: 130px auto 100px;
    padding: 10px 45px 30px 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    border-radius: 10px;
}
.form p[data-v-9116f47d]{
    font-size: 12px;
    padding: 5px;
    color: darkgray;
    margin: 1vh -49px 0 0px;
    cursor: pointer;
}
.form input[data-v-9116f47d]{
    outline: 0;
    border-radius: 10px;
    background: #F2F2F2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}
.form input[data-v-9116f47d]:hover{
    /* background-color: #D3F8F9; */
    transition: all 1s ease 0s;
}
.form input[data-v-9116f47d]:focus{
    /* background-color: #D3F8F9; */
    transition: all 1s ease 0s;
}
.form button[data-v-9116f47d]{
    text-transform: uppercase;
    outline: 0;
    border-radius: 10px;
    background: #1d1deb;
    width: 88%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
}
.form button[data-v-9116f47d]:hover, .form button[data-v-9116f47d]:active, .form button[data-v-9116f47d]:focus{
    background-color: #1b24c7;
    transition: all 1s ease 0s;
}
.form .message[data-v-9116f47d]{
    margin: 15px 0 0;
    color: #B3B3B3;
    font-size: 12px;
}
  /* .form .message a{
    color: #06C5CF;
    text-decoration: none;
  } */
.lab1[data-v-9116f47d]{
    float: left;
    padding: 7px;
    font-size: 12px;
    color: darkgray;
}
.form span[data-v-9116f47d]{
    position: absolute;
    right: 52px;
    top: 344px;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    color: gray;
    cursor: pointer;
    display: none;
}
.form input:valid ~span[data-v-9116f47d]{
    display: block;
}
#h4[data-v-9116f47d]{
    color:gray
}