@import url(https://fonts.googleapis.com/css?family=Roboto:300);
html[data-v-59dcf599] {
    background: red!important;
}
.wrapper[data-v-59dcf599]{
    width:100%;
    height:100%;
}
.login-page[data-v-59dcf599] {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form[data-v-59dcf599] {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0px auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 10px;
}
.form input[data-v-59dcf599] {
    font-family: "Roboto", sans-serif;
    outline: 0;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    background: #d9edeb;
    border-radius: 10px;
}
.form button[data-v-59dcf599] {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #000000;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  background: #EC8B5E;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.form button[data-v-59dcf599]:hover,.form button[data-v-59dcf599]:active,.form button[data-v-59dcf599]:focus {
  background: #EC8B5E;
}
.form .title[data-v-59dcf599]{
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: #000000;
}
.form .message[data-v-59dcf599] {
  margin: 15px 0 10px -145px;
  color: #b3b3b3;
}
.form .message a[data-v-59dcf599] {
  color: #EC8B5E;
  text-decoration: none;
}
.form .register-form[data-v-59dcf599] {
  display: none;
}
.container[data-v-59dcf599] {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container[data-v-59dcf599]:before, .container[data-v-59dcf599]:after {
  content: "";
  display: block;
  clear: both;
}
.container .info[data-v-59dcf599] {
  margin: 50px auto;
  text-align: center;
}
.container .info h1[data-v-59dcf599] {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span[data-v-59dcf599] {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a[data-v-59dcf599] {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa[data-v-59dcf599] {
  color: #EF3B3A;
}
body[data-v-59dcf599] {
  background-color: rgb(30, 30, 51);
  color: #fff;
}
hr[data-v-59dcf599]{
  border-bottom: 0.2px solid #070707;
  margin: 10px 0;
  width: 72%;
}