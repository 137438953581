.text1[data-v-58f0ce1d]{
    font-size: 17px;
    /* line-height: 44px; */
    letter-spacing: 0.2px;
    color: #9FA2B4;
}
.text2[data-v-58f0ce1d]{
    font-weight: 600;
    font-size: 14px;
    /* line-height: 20px; */
    letter-spacing: 0.2px;
    color: #252733;
}
#div1[data-v-58f0ce1d]{
    text-align: left;display: flex;
}
#h1[data-v-58f0ce1d]{
    color: darkgray;
}
#div2[data-v-58f0ce1d]{
    padding: 20px;
}
#div3[data-v-58f0ce1d]{
    text-align: left;padding: 106px;
}
#img1[data-v-58f0ce1d]{
    width: auto;
}
#img2[data-v-58f0ce1d]{
    width: 66px;
}